import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react'
import { makeStyles, Theme, withStyles } from '@material-ui/core/styles'
import {
  IconButton,
  Slider,
  Typography,
  Menu,
  MenuItem,
  Popover,
  ClickAwayListener,
  CircularProgress,
} from '@material-ui/core'
import {
  PlayArrow,
  Pause,
  VolumeUp,
  VolumeOff,
  MoreVert,
  Done,
  VolumeDown,
} from '@material-ui/icons'
import clsx from 'clsx'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
  },
  // container: {
  //   padding: theme.spacing(2),
  //   border: `1px solid ${theme.palette.divider}`,
  //   borderRadius: theme.shape.borderRadius,
  // },
  // progressContainer: {
  //   flexGrow: 1,
  //   flexBasis: '100%',
  //   height: 4,
  //   // ...
  //   marginTop: theme.spacing(1),
  //   marginBottom: theme.spacing(1),
  // },
  // controlsContainer: {
  //   display: 'flex',
  //   alignItems: 'center',
  //   gap: theme.spacing(1),
  //   flexWrap: 'wrap',
  //   width: '100%',
  // },
  playButton: {
    marginRight: theme.spacing(1),
    flexShrink: 0,
  },
  timeDisplay: {
    minWidth: 100,
    flexShrink: 0,
    marginRight: theme.spacing(1),
  },
  slider: {
    flex: 1,
    marginRight: theme.spacing(1),
  },
  volumeControl: {
    position: 'relative',
  },
  volumeSlider: {
    padding: theme.spacing(2),
    height: 150,
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
  },
  menuButton: {
    marginLeft: theme.spacing(1),
    flexShrink: 0,
  },
}))

const TimelineSeek = withStyles({
  root: {
    height: 4,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 2,
  },
  rail: {
    height: 8,
    borderRadius: 2,
  },
})(Slider)

// const VolumeSeek = withStyles({
//   root: {
//     height: 4,
//   },
//   thumb: {
//     height: 24,
//     width: 24,
//     backgroundColor: '#fff',
//     border: '2px solid currentColor',
//     marginTop: -8,
//     marginLeft: -12,
//     '&:focus, &:hover, &$active': {
//       boxShadow: 'inherit',
//     },
//   },
//   active: {},
//   valueLabel: {
//     left: 'calc(-50% + 4px)',
//   },
//   track: {
//     width: 8,
//     borderRadius: 2,
//   },
//   rail: {
//     width: 8,
//     borderRadius: 2,
//   },
// })(Slider)
interface AudioPlayerProps {
  src: string
  volume?: number
  onVolumeChange?: (volume: number) => void
  playbackRate?: number
  onPlaybackRateChange?: (rate: number) => void
  onPlay?: () => void
  onPause?: () => void
  onProgressChange?: (progress: number) => void
  // withoutContainer?: boolean
}

const AudioPlayer: React.FC<AudioPlayerProps> = React.memo(
  ({
    src,
    volume: initialVolume = 1,
    onVolumeChange,
    playbackRate: initialPlaybackRate = 1,
    onPlaybackRateChange,
    onPlay,
    onPause,
    onProgressChange,
    // withoutContainer = false,
    ...props
  }) => {
    const classes = useStyles()
    const audioRef = useRef<HTMLAudioElement | null>(null)
    const [isPlaying, setIsPlaying] = useState(false)
    const [progress, setProgress] = useState(0)
    const [volume, setVolume] = useState(initialVolume)
    const [isMuted, setIsMuted] = useState(initialVolume === 0)
    const [duration, setDuration] = useState(0)
    const [currentTime, setCurrentTime] = useState(0)
    const [isVolumeOpen, setIsVolumeOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const [playbackRate, setPlaybackRate] = useState(initialPlaybackRate)
    const [isLoaded, setIsLoaded] = useState(false)
    const [isBuffering, setIsBuffering] = useState(true)
    const [
      isHandlingPlaybackRateChange,
      setIsHandlingPlaybackRateChange,
    ] = useState(false)

    useEffect(() => {
      if (!audioRef.current) return
      setDuration(audioRef.current.duration)
    }, [audioRef?.current?.duration])

    useEffect(() => {
      const audio = new Audio(src)
      audioRef.current = audio

      const setAudioData = () => {
        setDuration(audio.duration)
        setIsLoaded(true)
        setIsBuffering(false)
      }

      const handleCanPlayThrough = () => {
        setIsLoaded(true)
      }

      const forceBuffering = () => {
        if (!audioRef.current) return

        setIsBuffering(true)

        const step = 1 // Number of seconds to jump for each buffer step
        let currentTime = 0

        const bufferingInterval = setInterval(() => {
          if (currentTime < audioRef.current.duration) {
            audioRef.current.currentTime = currentTime
            currentTime += step
          } else {
            clearInterval(bufferingInterval)
            audioRef.current.currentTime = 0 // Reset to start
            setIsLoaded(true)
            setIsBuffering(false)
          }
        }, 100) // Adjust interval as needed
      }

      const setAudioTime = () => {
        setCurrentTime(audio.currentTime)
        setProgress((audio.currentTime / audio.duration) * 100)
        onProgressChange?.((audio.currentTime / audio.duration) * 100)
      }

      const handleEnded = () => {
        setIsPlaying(false)
        setProgress(0)
        setCurrentTime(0)
      }

      audio.addEventListener('loadeddata', setAudioData)
      audio.addEventListener('canplaythrough', handleCanPlayThrough)
      audio.addEventListener('timeupdate', setAudioTime)
      audio.addEventListener('ended', handleEnded)

      audio.preload = 'auto'
      audio.volume = initialVolume
      audio.playbackRate = initialPlaybackRate
      audio.load()
      forceBuffering()

      setIsBuffering(true)

      return () => {
        audio.removeEventListener('loadeddata', setAudioData)
        audio.removeEventListener('canplaythrough', handleCanPlayThrough)
        audio.removeEventListener('timeupdate', setAudioTime)
        audio.removeEventListener('ended', handleEnded)
      }
    }, [src])

    const togglePlay = useCallback(() => {
      if (!audioRef.current || isBuffering) return
      if (isPlaying) {
        audioRef.current.pause()
        onPause?.()
      } else {
        audioRef.current.play()
        onPlay?.()
      }
      setIsPlaying(!isPlaying)
    }, [isPlaying, isBuffering, onPlay, onPause])

    const handleProgressChange = useCallback(
      (_: any, newValue: number | number[]) => {
        if (!audioRef.current || !isLoaded) return
        const newTime = ((newValue as number) / 100) * audioRef.current.duration
        if (isFinite(newTime)) {
          audioRef.current.currentTime = newTime
          setProgress(newValue as number)
          onProgressChange?.(newValue as number)
        }
      },
      [isLoaded, onProgressChange]
    )

    const handleVolumeChange = useCallback(
      (_: any, newValue: number | number[]) => {
        if (!audioRef.current) return
        const newVolume = (newValue as number) / 100
        audioRef.current.volume = newVolume
        setVolume(newVolume)
        setIsMuted(newVolume === 0)
        onVolumeChange?.(newVolume)
      },
      [onVolumeChange]
    )

    const toggleMute = useCallback(() => {
      if (!audioRef.current) return
      const newMutedState = !isMuted
      audioRef.current.muted = newMutedState
      setIsMuted(newMutedState)
      if (newMutedState) {
        setVolume(0)
        onVolumeChange?.(0)
      } else {
        setVolume(audioRef.current.volume)
        onVolumeChange?.(audioRef.current.volume)
      }
    }, [isMuted, onVolumeChange])

    const handleMenuClick = useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
      },
      []
    )

    const handleMenuClose = useCallback(() => {
      setAnchorEl(null)
      setIsHandlingPlaybackRateChange(false)
    }, [])

    const handlePlaybackRateChange = useCallback(
      (rate: number) => {
        if (audioRef.current) {
          audioRef.current.playbackRate = rate
          setPlaybackRate(rate)
          onPlaybackRateChange?.(rate)
        }
        handleMenuClose()
      },
      [handleMenuClose, onPlaybackRateChange]
    )

    const formatTime = useCallback((time: number) => {
      if (!isFinite(time)) return '--:--'
      const minutes = Math.floor(time / 60)
      const seconds = Math.floor(time % 60)
      return `${minutes}:${seconds.toString().padStart(2, '0')}`
    }, [])

    const formattedCurrentTime = useMemo(() => formatTime(currentTime), [
      currentTime,
      formatTime,
    ])
    const formattedDuration = useMemo(() => formatTime(duration), [
      duration,
      formatTime,
    ])

    const PlayButton = useMemo(
      () => (
        <IconButton
          onClick={togglePlay}
          className={classes.playButton}
          disabled={isBuffering}
        >
          {isBuffering ? (
            <CircularProgress size={24} />
          ) : isPlaying ? (
            <Pause />
          ) : (
            <PlayArrow />
          )}
        </IconButton>
      ),
      [classes.playButton, isPlaying, togglePlay, isBuffering]
    )

    const TimeDisplay = useMemo(
      () => (
        // !isBuffering && isLoaded ? (
        <Typography variant="body2" className={classes.timeDisplay}>
          {formattedCurrentTime} / {formattedDuration}
        </Typography>
        // ) : null,
      ),
      [
        classes.timeDisplay,
        formattedCurrentTime,
        formattedDuration,
        isBuffering,
        isLoaded,
      ]
    )

    const ProgressSlider = useMemo(
      () => (
        // !isBuffering && isLoaded ? (
        <TimelineSeek
          className={classes.slider}
          value={progress}
          onChange={handleProgressChange}
          aria-labelledby="continuous-slider"
          disabled={isBuffering || !isLoaded}
        />
      ),
      // ) : null,
      [classes.slider, progress, handleProgressChange, isLoaded, isBuffering]
    )

    const VolumeControl = useMemo(
      () => (
        <div className={classes.volumeControl}>
          <IconButton
            onClick={toggleMute}
            onMouseEnter={() => setIsVolumeOpen(true)}
            id="volume-button"
          >
            {isMuted ? (
              <VolumeOff />
            ) : volume >= 0.5 ? (
              <VolumeUp />
            ) : (
              <VolumeDown />
            )}
          </IconButton>
          <Popover
            open={isVolumeOpen}
            anchorEl={document.getElementById('volume-button')}
            onClose={() => setIsVolumeOpen(false)}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
          >
            <ClickAwayListener onClickAway={() => setIsVolumeOpen(false)}>
              <div className={classes.volumeSlider}>
                {/* <VolumeSeek */}
                <Slider
                  orientation="vertical"
                  value={volume * 100}
                  onChange={handleVolumeChange}
                  aria-labelledby="vertical-slider"
                />
              </div>
            </ClickAwayListener>
          </Popover>
        </div>
      ),
      [
        classes.volumeControl,
        classes.volumeSlider,
        isMuted,
        isVolumeOpen,
        toggleMute,
        volume,
        handleVolumeChange,
      ]
    )

    const MenuButton = useMemo(
      () => (
        <IconButton
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleMenuClick}
          className={classes.menuButton}
        >
          <MoreVert />
        </IconButton>
      ),
      [classes.menuButton, handleMenuClick]
    )

    const PlaybackRateControl = useMemo(
      () => (
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          {isHandlingPlaybackRateChange ? (
            [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2].map(rate => (
              <>
                {/* Do not remove the React fragment, or the menu item will offset away based on your selected item's position*/}
                <MenuItem
                  key={rate}
                  selected={playbackRate === rate}
                  onClick={() => handlePlaybackRateChange(rate)}
                >
                  {playbackRate === rate && (
                    <Done
                      style={{
                        marginRight: '0.5rem',
                        fontSize: '1rem',
                      }}
                    />
                  )}
                  {rate === 1 ? 'Normal' : `${rate}x`}
                </MenuItem>
              </>
            ))
          ) : (
            <>
              <MenuItem onClick={() => setIsHandlingPlaybackRateChange(true)}>
                Playback Rate
              </MenuItem>
              <MenuItem
                component="a"
                href={src}
                download
                // onClick={async () => {
                //   const handleDownload = async () => {
                //     try {
                //       const response = await fetch(src, { mode: 'no-cors' })
                //       const blob = await response.blob()
                //       const url = URL.createObjectURL(blob)
                //       const link = document.createElement('a')
                //       link.href = url
                //       link.download = 'audio.mp3'
                //       link.click()
                //       URL.revokeObjectURL(url)
                //     } catch (error) {
                //       console.error('Error downloading audio:', error)
                //     }
                //   }

                //   handleDownload()
                // }}
              >
                Download
              </MenuItem>
            </>
          )}
        </Menu>
      ),
      [
        anchorEl,
        handleMenuClose,
        isHandlingPlaybackRateChange,
        playbackRate,
        handlePlaybackRateChange,
        src,
      ]
    )

    return (
      <div
        className={clsx(classes.root, {
          // [classes.container]: !withoutContainer,
        })}
        {...props}
      >
        {/* <div className={classes.controlsContainer}> */}
        {PlayButton}
        {TimeDisplay}
        {ProgressSlider}
        {VolumeControl}
        {MenuButton}
        {PlaybackRateControl}
        {/* </div> */}
      </div>
    )
  }
)

export default AudioPlayer
