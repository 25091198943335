import React from 'react'
interface ContentWrapperProps {
  children?: any
  footer?: boolean
  page?: string
  float?: boolean
}
export const ContentWrapper = (props: ContentWrapperProps) => {
  const { children, footer, page, float } = props
  var footing = ''

  if (footer == true && float == true) {
    footing = 'with-footer-n-float'
  } else if (footer == true && float == null) {
    footing = 'with-footer'
  } else if (footer == null && float == true) {
    footing = 'with-float'
  }

  return <div className={`content-wrapper ${footing}`}>{children}</div>
}
